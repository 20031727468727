<template>
  <div>
    <!-- Filters -->
    <Filters
      v-model="filter"
      :loading.sync="loading"
      :month.sync="monthFilter"
      :year.sync="yearFilter"
      @search="handleSearch"
      @clear-filter="handleClearFilter"
    />
    <b-overlay
      id="overlay-bg-customer-list"
      class="custom-overlay-icon-top"
      :show="loading"
      variant="white"
      opacity=".5"
      blur="2px"
      rounded="sm"
    >
      <!--    table-->
      <b-card
        no-body
        class="mb-0 pb-0"
      >
        <div
          class="py-1 px-2 d-flex justify-content-between"
        >
          <div v-html="'Tìm thấy ' + (totalCustomers || 0) + ' kết quả'" />
          <table-column-toggle
            id="refCustomerListTable"
            :table-columns="tableColumns"
            @update-columns-show="updateTableColumns"
          />
        </div>

        <b-table
          ref="refCustomerListTable"
          class="position-relative custom-head thead-no-wrap line-height-12 font-small-4"
          responsive
          hover
          primary-key="id"
          show-empty
          small
          empty-text="Không có kết quả nào"
          :items="customers"
          :fields="tableColumns"
          @row-clicked="rowClicked"
        >
          <template #cell(id)="data">
            <span class="d-block font-weight-bold text-primary">{{ data.item.id }}</span>
            <div class="customer-badge-wrapper">
              <b-badge
                v-if="data.item.is_hot"
                class="customer-badge hot"
                variant="danger"
              >HOT
              </b-badge>
              <b-badge
                v-if="data.item.has_corner"
                variant="success"
                class="customer-badge corner"
              >GÓC
              </b-badge>
            </div>
          </template>

          <template #cell(image)="data">
            <b-media-aside class="mr-75">
              <b-avatar
                square
                size="64"
                :src="data.item.image ? getUrlFile(data.item.image, data.item.updated_at, showImage160.includes(data.item.image || '') ? '240' : '160') : ''"
                :text="data.item.image ? '' : 'CL'"
                @img-error="() => showImage160.push(data.item.image)"
              />
            </b-media-aside>
          </template>

          <template #cell(title)="data">
            <div
              class="py-50 font-small-3"
              :class="data.item.is_close_deal ? 'text-danger' : 'text-primary'"
            >
              <template v-if="data.item.is_close_deal">
                [CLOSE DEAL]
              </template>{{ data.item.title }}
            </div>
          </template>

          <template #cell(price)="data">
            <div class="text-primary text-nowrap">
              <span class="font-weight-bold">
                {{ formatNumber(data.item.input_price) }} <small>{{ (CURRENCY.find(item => item.value === data.item.currency) || {text: 'usd'}).text.toUpperCase() }}</small>
              </span>
              <template v-if="data.item.type_of_payment === 'month'">
                <br>
                / tháng
              </template>
            </div>
          </template>

          <template #cell(nguoi-dang)="data">
            {{ data.item.user.fullname }}
          </template>

          <template #cell(type)="data">
            <small class="text-nowrap">{{
              (TYPE_PRODUCT.find(item => item.value === data.item.type) || {}).text || ''
            }}</small>
          </template>

          <template #cell(thang-may)="data">
            {{ data.item.elevator ? 'Có' : 'Không' }}
          </template>

          <template #cell(expand_style)="data">
            {{ (EXPAND_STYLE.find(item => item.value === data.item.expand_style) || {}).text || '' }}
            <template v-if="data.item.expand_style_info">
              <br>
              ({{ data.item.expand_style_info }} m)
            </template>
          </template>

          <template #cell(ngay-dang)="data">
            {{ formatDate(data.item.created_at) }}
          </template>

          <template #cell(actions)="data">
            <div class="customer-actions-wrapper">
              <b-button
                v-if="ability().can('edit', subject('CustomerCorner', data.item))"
                variant="outline-primary"
                size="sm"
                class="p-50"
                @click="gotoCustomerEdit(data.item)"
              >
                <feather-icon
                  icon="EditIcon"
                />
              </b-button>
              <b-button
                v-if="ability().can('delete', subject('CustomerCorner', data.item))"
                variant="outline-danger"
                size="sm"
                class="p-50"
                @click="openModalConfirmDeleteCustomer(data.item)"
              >
                <feather-icon
                  icon="TrashIcon"
                />
              </b-button>
            </div>
          </template>

        </b-table>

        <div class="mb-3">
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start mb-1 mb-md-0"
            >
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>mỗi trang</label>
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalCustomers"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>

    <b-modal
      id="modal-confirm-delete-customer"
      ref="modal-confirm-delete-customer"
      cancel-title="Hủy"
      ok-title="Xác nhận"
      size="lg"
      :no-close-on-backdrop="true"
      centered
      header-text-variant="danger"
      :title="'Xóa' + (customerMenuType ? ' Sản Phẩm Thuê #' : ' Sản Phẩm Bán #') + currentCustomer.id"
      @ok="handleDeleteCustomer"
    >
      <b-card-text>
        Bạn thực sự muốn <span class="text-danger">xóa</span>
        {{ (customerMenuType ? ' Sản Phẩm Thuê' : ' Sản Phẩm Bán') + ' mã số: ' }}
        <span class="text-primary font-weight-bolder">{{ (currentCustomer.id) }}</span> ?
        <br>
        <br>
        <small class="text-primary">{{ currentCustomer.title }}</small>
        <br>
        <br>
        <b-button
          variant="primary"
          size="sm"
          @click="gotoCustomerDetail(currentCustomer)"
        >
          Xem chi tiết
        </b-button>
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-export-excel"
      ref="modal-export-excel"
      cancel-title="Hủy"
      ok-title="Xuất excel"
      size="lg"
      :no-close-on-backdrop="true"
      centered
      header-text-variant="primary"
      title="Chọn thời gian xuất Excel"
      @ok="handleExportExcel"
    >
      <b-card-text>
        <b-row>
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group label="Thời gian">
              <b-form-radio-group
                v-model="exportExcelTime"
                stacked
              >
                <b-form-radio value="week">
                  Theo tuần (7 ngày qua)
                </b-form-radio>
                <b-form-radio value="month">
                  Theo tháng (30 ngày qua)
                </b-form-radio>
                <b-form-radio value="">
                  Tất cả thời gian
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group label="Close Deal">
              <b-form-radio-group
                v-model="exportExcelCloseDeal"
                stacked
              >
                <b-form-radio value="">
                  Tất cả
                </b-form-radio>
                <b-form-radio value="active">
                  Close Deal
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group>
              <label>Từ</label>
              <flat-pickr
                id="move_in_at"
                v-model="timeFrom"
                class="form-control"
                :config="{ altFormat: 'd-m-Y', altInput: true, mode: 'single', allowInvalidPreload: true, dateFormat: 'Y-m-d', allowInput: true }"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <b-form-group>
              <label>Đến</label>
              <flat-pickr
                id="move_in_at"
                v-model="timeTo"
                class="form-control"
                :config="{ altFormat: 'd-m-Y', altInput: true, mode: 'single', allowInvalidPreload: true, dateFormat: 'Y-m-d', allowInput: true }"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>

    <actions
      :buttons="actionsButton"
      @click="handleActionsButton"
    />
  </div>
</template>

<script>
import {
  BAvatar, BCard, BCol, BMediaAside, BPagination, BRow, BTable, VBTooltip, BBadge, BButton, BOverlay,
  BCardText, BFormGroup, BFormRadioGroup, BFormRadio,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import customerStoreModule from '@/views/customer-hotel/customerStoreModule'
import TableColumnToggle from '@core/components/TableColumnToggle.vue'
import useCustomerList from '@/views/customer-hotel/list/useCustomerList'
import ability from '@/libs/acl/ability'
import { subject } from '@casl/ability'
import flatPickr from 'vue-flatpickr-component'
import Actions from '@core/components/fixed-button/Fixed-Right-Button.vue'
import Filters from './Filters.vue'

export default {
  components: {
    Filters,
    BAvatar,
    BCard,
    BTable,
    TableColumnToggle,
    BPagination,
    vSelect,
    BRow,
    BCol,
    BCardText,
    BMediaAside,
    BBadge,
    BButton,
    BOverlay,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    flatPickr,
    Actions,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading: false,
      ability,
      subject,
    }
  },
  computed: {
    actionsButton() {
      const validActions = []
      if (ability().can('export-excel')) {
        validActions.push(this.ACTIONS_BUTTON.EXPORT_EXCEL)
      }
      return validActions
    },
  },
  created() {
    const { filter } = this.$route.query
    Object.assign(this.filter, JSON.parse(filter || '""'))
    this.monthFilter = this.filter.monthFilter || ''
    this.yearFilter = this.filter.yearFilter || ''
    store.dispatch('app/fetchUsers')
    this.filter.province_id = '1' // HCM
    this.fetchCustomers().finally(() => {
      this.loading = false
    })
    // waiting for watching done
    setTimeout(() => { this.created = true }, 500)
  },
  methods: {
    gotoCustomerEdit(customer) {
      this.$router.push({ name: `customer-${this.customerMenuType}-edit`, params: { id: customer.id } })
    },
    handleActionsButton(btn) {
      if (btn.value === 'excel') {
        this.$refs['modal-export-excel'].show()
      }
    },
    handleExportExcel() {
      this.$toastr('Đang xuất file, vui lòng chờ giây lát!')
      this.$call(this.exportExcelCustomer().then(res => {
        this.downloadUrl(res.data, 'excel')
      }))
    },
    openModalConfirmDeleteCustomer(customer) {
      this.currentCustomer = customer
      this.$refs['modal-confirm-delete-customer'].show()
    },
    handleDeleteCustomer() {
      this.$call(this.deleteCustomer(this.currentCustomer.id), true).then(() => {
        const foundIdx = this.customers.findIndex(item => item.id === this.currentCustomer.id)
        if (foundIdx !== -1) {
          this.customers.splice(foundIdx, 1)
        }
      })
    },
    gotoCustomerDetail({ id }) {
      const route = this.$router.resolve({
        name: `customer-${this.customerMenuType}-detail`,
        params: { id },
      })
      window.open(route.href, this.$store.state.app.isDev ? '_self' : '')
    },
    handleSearch() {
      this.$router.replace({
        query: {
          filter: JSON.stringify(this.filter || ''),
        },
      })
      this.fetchCustomers().finally(() => {
        this.loading = false
      })
    },
    handleClearFilter(initFilter) {
      this.clearFilter(initFilter)
    },
    updateTableColumns(tableColumns) {
      this.tableColumns = tableColumns
    },
    rowClicked(row) {
      this.gotoCustomerDetail(row)
    },
  },
  setup() {
    const CUSTOMER_STORE_MODULE_NAME = 'customer'
    if (!store.hasModule(CUSTOMER_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_STORE_MODULE_NAME, customerStoreModule)
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_STORE_MODULE_NAME)
    })

    return {
      ...useCustomerList(),
    }
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';
.per-page-selector {
  width: 90px;
}

.custom-head th {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.table .feather {
  min-width: 1rem;
}

.customer-actions-wrapper {
  display: flex;
  gap: 1rem;

  > * {
    padding: .25rem !important;
  }

  .customer-badge {
    position: absolute;
    right: 0;
    font-size: .7rem !important;

    &.hot {
      top: .25rem;
    }

    &.corner {
      bottom: .25rem;
    }
  }
}
</style>
